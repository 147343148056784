import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private cache: { [key: string]: { data: any; expiry: number } } = {};
  private defaultCacheTTL = 300000; // Default TTL in milliseconds (e.g., 5 minutes)

  constructor(private http: HttpClient) {}

  getData(
    url: string,
    params: { [key: string]: any } = {},
    cacheTTL: number = this.defaultCacheTTL,
  ): Observable<any> {
    const cacheKey = this.createCacheKey(url, params);
    const cachedResponse = this.cache[cacheKey];
    const isCacheValid = cachedResponse && Date.now() < cachedResponse.expiry;

    if (isCacheValid) {
      // Return cached data if it's still valid
      return of(cachedResponse.data);
    } else {
      // Set up HttpParams from the provided params object
      let httpParams = new HttpParams();
      Object.keys(params).forEach((key) => {
        httpParams = httpParams.set(key, params[key]);
      });

      // Fetch from API and update the cache
      return this.http.get(url, { params: httpParams }).pipe(
        tap((data) => {
          this.cache[cacheKey] = { data, expiry: Date.now() + cacheTTL };
        }),
      );
    }
  }

  // Helper method to create a unique cache key based on URL and parameters
  private createCacheKey(url: string, params: { [key: string]: any }): string {
    const sortedParams = Object.keys(params)
      .sort()
      .map((key) => `${key}=${params[key]}`)
      .join('&');
    return `${url}?${sortedParams}`;
  }

  // Method to clear specific cache entries by URL and parameters
  clearCache(url: string, params: { [key: string]: any } = {}): void {
    const cacheKey = this.createCacheKey(url, params);
    delete this.cache[cacheKey];
  }

  // Method to clear the entire cache
  clearAllCache(): void {
    this.cache = {};
  }
}
