import { Component, OnInit, effect, signal } from '@angular/core';
import {
  of,
  map,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  Subject,
} from 'rxjs';

@Component({
  selector: 'xpw-search-input',
  template: `
    <nz-input-group [nzSuffix]="suffixTemplateInfo" [nzSize]="'large'">
      <input
        type="text"
        nz-input
        i18n-placeholder
        placeholder="Search"
        (keydown.enter)="onChange()"
        [(ngModel)]="searchText"
      />
    </nz-input-group>
    <ng-template #suffixTemplateInfo>
      @if (searchText) {
        <span
          nz-icon
          nz-tooltip
          nzTooltipTitle="delete search"
          nzType="xpw-outline:clean-search"
          (click)="clearSearch()"
        ></span>
      } @else {
        <span
          nz-icon
          nz-tooltip
          nzTooltipTitle="type to search..."
          nzType="xpw-outline:search"
        ></span>
      }
    </ng-template>
  `,
  styleUrls: ['./xpw-search-input.component.less'],
})
export class XpwSearchInputComponent implements OnInit {
  searchText: string = '';
  keyPress$ = new Subject();

  // Define a signal to reactively hold the current search field value
  searchFieldChangedSignal = signal<string>('');
  constructor() {
    effect(() => {
      const searchValue = this.searchFieldChangedSignal();
      console.log('Search field updated:', searchValue);
      // You can trigger further actions here if needed
    });
  }
  ngOnInit() {
    this.keyPress$
      .pipe(
        debounceTime(10),
        distinctUntilChanged(),
        switchMap((term) => of(term)),
      )
      .subscribe((result: any) => {
        this.searchFieldChangedSignal.set(result);
      });

    // Add an effect to monitor changes to searchFieldChangedSignal
  }

  onChange() {
    this.keyPress$.next(this.searchText);
  }

  clearSearch() {
    this.searchText = '';
    this.onChange();
  }
}
