<div class="container main-navigation">
  <div class="sidebar-logo">
    <img
      src="./assets/logo/xpw-full-logo.svg"
      style="height: 40px"
      alt="logo"
      *ngIf="!isCollapsed"
    />
    <img
      src="./assets/logo/xpw-icon-logo.svg"
      alt="logo"
      style="width: 40px"
      *ngIf="isCollapsed"
    />

    <span class="header-trigger anticon" (click)="changeCollapse()">
      <span nz-icon nzType="caret-right" nzTheme="fill" *ngIf="isCollapsed">
      </span>
      <span
        nz-icon
        nzType="caret-left"
        nzTheme="fill"
        *ngIf="!isCollapsed"
      ></span>
    </span>
  </div>

  <div class="border-line"></div>

  <ul
    style="overflow-y: auto; overflow-x: hidden"
    nz-menu
    nzTheme="light"
    nzMode="inline"
    *ngIf="isLoggedIn()"
    [nzInlineCollapsed]="isCollapsed"
  >
    <ng-template ngFor let-item [ngForOf]="mainNavigation">
      <li
        nz-submenu
        nzTitle="{{ item.title }}"
        nzIcon="nav-icons:{{ item.icon }}"
        nzOpen="{{ item.open }}"
        (nzOpenChange)="onItemNavOpenChange(item)"
        *ngIf="shouldView(item)"
      >
        <ul>
          <ng-template ngFor let-grand [ngForOf]="item.children">
            <li
              nz-menu-item
              nzIcon="nav-icons:ellipse"
              (click)="goTo(grand.url)"
              [nzSelected]="grand.selected"
              *ngIf="shouldView(grand)"
              nzDisabled="{{ grand.disabled == true }}"
            >
              <a>
                {{ grand.title }}
              </a>
            </li>
          </ng-template>
        </ul>
      </li>
    </ng-template>
  </ul>

  <section class="bottom-items">
    <ul nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed"></ul>
    <ul
      nz-menu
      nzTheme="light"
      nzMode="inline"
      [nzInlineCollapsed]="isCollapsed"
    >
      <li nzDisabled nz-menu-item nz-tooltip nzTooltipPlacement="right">
        <span nz-icon nzType="nav-icons:info"></span>
        <span>About</span>
      </li>
    </ul>

    <ul
      nz-menu
      nzTheme="light"
      nzMode="inline"
      [nzInlineCollapsed]="isCollapsed"
    >
      <li nzDisabled nz-menu-item nz-tooltip nzTooltipPlacement="right">
        <span nzDisabled nz-icon nzType="nav-icons:contact-us"></span>
        <span>Contact Us</span>
      </li>
    </ul>
    <ul
      nz-menu
      nzTheme="light"
      nzMode="inline"
      [nzInlineCollapsed]="isCollapsed"
    >
      <li nzDisabled nz-menu-item nz-tooltip nzTooltipPlacement="right">
        <span nz-icon nzType="nav-icons:help"></span>
        <span>Support</span>
      </li>
    </ul>
    <!-- <ul
      nz-menu
      nzTheme="light"
      nzMode="inline"
      [nzInlineCollapsed]="isCollapsed"
    >
      <li nz-menu-item nz-tooltip nzTooltipPlacement="right" (click)="logout()">
        <span nz-icon nzType="nav-icons:help"></span>
        <span>Logout</span>
      </li>
    </ul> -->
  </section>
</div>
