import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  output,
  ViewChild,
  AfterViewInit,
  effect,
} from '@angular/core';
import { XpwSearchInputComponent } from '@shared/xpw-ui-kit/basic/search-input/xpw-search-input.component';

@Component({
  selector: 'xpw-page-list-header',
  template: `
    <div class="flex-row space-between page-list-header">
      <div>
        <h2>
          {{ title }}
          <span
            nz-tooltip
            i18n-nzTooltipTitle
            nzTooltipTitle="Last Proccessing"
            nzTooltipPlacement="top"
            *ngIf="lastProccesingDate"
            class="badge"
          >
            <xpw-icon icon="processing"> </xpw-icon>
            {{ lastProccesingDate | xpwDateTimeFormatter }}</span
          >
          <span class="badge">{{ itemCount }}</span>
        </h2>
      </div>
      <div class="flex space-between center-elements">
        <xpw-search-input *ngIf="showSearchField"></xpw-search-input>

        <xpw-button
          *ngIf="showFilters"
          type="default"
          class="filter-button"
          (click)="openFilterDrawer.emit()"
        >
          <xpw-icon icon="filter" [isPadding]="false" />
        </xpw-button>
      </div>
      <div class="flex space-between">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./xpw-page-list-header.component.less'],
})
export class XpwPageListHeaderComponent {
  @ViewChild(XpwSearchInputComponent)
  searchInputComponent!: XpwSearchInputComponent;

  dropdownItems = [
    { label: 'Excel', value: 'exportExcel', icon: 'file-excel' },
  ];

  constructor() {
    effect(() => {
      this.searchText.emit(
        this.searchInputComponent.searchFieldChangedSignal(),
      );
    });
  }
  @Input() title: string = '';
  @Input() lastProccesingDate: Date | null = null;
  @Input() itemCount: number = 0;
  @Input() showSearchField: boolean = true;
  @Input() showFilters: boolean = true;
  @Output() searchText = new EventEmitter<string>();
  @Output() openFilterDrawer = new EventEmitter();
}
