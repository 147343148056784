// Interface for working with any MeterGroup.

export interface IMeterGroup {
  meterGroupUID: string;
  meterGroupName: string;
  organizationUID: string;
  parentMeterGroupUID: string | null;
}

export interface IMeterGroupView extends IMeterGroup {
  createDate: Date;
  groupType: GroupType;
  children: IMeterGroupView[];
  usersCount: number;
  selected: boolean;
  metersCount: number;
}

export enum GroupType {
  Parent = 'Parent',
  Meter = 'Meter',
}

/* export interface IGroup {
  meterGroupUID: string;
  meterGroupName: string;
  createDate: Date;
  organizationUID: string;
  groupType: GroupType;
  children: IGroup[];
  meterUIDs: string[];
} */
