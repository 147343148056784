import { DepartmentEffects } from '@features/departments/departments-store/departments.effects';
import { CurrentUserEffects } from './current-user/current-user.effects';
import { OrganizationEffects } from '@features/organizations/organizations-store/organizations.effects';
import { LocationEffects } from './location/location.effects';
import { MeterEffects } from '@features/meters/meters-store/meters.effects';
import { FileImportEffects } from './file-import/file-import.effects';
import { MeterGroupEffects } from '@features/meter-group/store/meter-group.effects';

export const rootEffects = [
  CurrentUserEffects,
  DepartmentEffects,
  OrganizationEffects,
  LocationEffects,
  MeterEffects,
  FileImportEffects,
  MeterGroupEffects,
];
