import {
  IFilterOptions,
  IPagingRequest,
  initialPagingRequest,
  initialPagingResponse,
} from '@shared/utility/global-enums';
import {
  IMeter,
  IMetersResult,
  IReplacmentHystory,
  MeasuringPointType,
  MeterName,
  MeterStatus,
} from './meters.interface';

import { UtilityService } from '@features/organizations/organizations-store/organizations.interface';

export interface IMeterState {
  meters: IMetersResult;
  meterNameList: MeterName[] | null;
  selectedMeter: IMeter;
  replacementHistory: IReplacmentHystory;
  pagingRequest: IPagingRequest;
  filter: {
    MeterUID: string[];
    MeterGroupUID: string[];
    UtilityServiceId: UtilityService[];
    MeasuringPointTypeId: MeasuringPointType[];
    PowerSourceUID: string[];
    IsVirtual: string[];
    IsOppositeConfiguration: string[];
    StateId: string[];
    City: string[];
    ChannelConfigurationUID: string[];
  };
  search: string;
  filterOptions: IFilterOptions[];
  drawersState: {
    meterEditDrawer: boolean;
    replacementEditDrawer: boolean;
  };
  ValidationErrors: any;
  isNewMeter: boolean;
}

export const initialMeterState: IMeterState = {
  meters: { items: [], pagingInfo: initialPagingResponse, isLoading: true },
  meterNameList: [],
  selectedMeter: {
    meterUID: null,
    internalMeterID: null,
    createDate: null,
    lastConsumption: null,
    meterGeneralInfo: {
      meterName: '',
      utilityServiceID: null,
      measuringPointTypeID: null,
      timeZoneID: '',
      meterStatusID: MeterStatus.Active,
      powerSourceUID: null,
      stateID: null,
      city: '',
      zipCode: null,
      streetAddress: null,
      latitude: null,
      longitude: null,
      locationID: null,
      isVirtual: false,
    },
    currentReplacement: {
      meterReplacementUID: null,
      externalID: null,
      serialNumber: null,
      installationDate: null,
      endDate: null,
      multiplier: 1,
      channelConfigurationUID: null,
      isOppositeConfiguration: false,
      isCurrent: false,
    },
    transformerSetting: {
      transformerTypeID: null,
      nominalValueKVA: null,
      optimalLoadPercentage: 60,
      typicalPfForPvProducer: 1,
      typicalPfForConsumer: 0.85,
      overloadHoursThreshold: 2,
    },
    groupUIDs: null,
  },
  replacementHistory: {
    items: [],
    selectedReplacement: {
      meterReplacementUID: null,
      externalID: null,
      serialNumber: null,
      installationDate: null,
      endDate: null,
      multiplier: 1,
      channelConfigurationUID: null,
      isOppositeConfiguration: false,
      isCurrent: false,
    },
    isNewReplacement: true,
  },
  pagingRequest: initialPagingRequest,
  filter: {
    MeterUID: [],
    MeterGroupUID: [],
    PowerSourceUID: [],
    UtilityServiceId: [],
    MeasuringPointTypeId: [],
    IsVirtual: [],
    IsOppositeConfiguration: [],
    StateId: [],
    City: [],
    ChannelConfigurationUID: [],
  },
  search: '',
  filterOptions: [],
  drawersState: {
    meterEditDrawer: false,
    replacementEditDrawer: false,
  },
  ValidationErrors: null,
  isNewMeter: false,
};
