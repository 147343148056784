import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  ConfirmDialogData,
  ConfirmDialogService,
} from '@core/services/confirm-dialog/confirm-dialog.service';
import { Store } from '@ngrx/store';
import {
  clearAuditAction,
  updateAuditAction,
} from 'src/app/store/audit/audit.actions';

@Component({
  selector: 'xpw-confirm-dialog',
  template: `
    <nz-modal [(nzVisible)]="isVisible" [nzClosable]="false">
      <div *nzModalTitle>
        <span
          nz-icon
          nzType="exclamation-circle"
          nzTheme="fill"
          style="color: rgba(255, 169, 64, 1);"
        ></span>
        {{ confirmDialogData.title }}
      </div>
      <ng-container *nzModalContent>
        <ng-container *ngIf="confirmDialogData.content">
          <!-- Check if content contains HTML tags and use [innerHTML] to display it; otherwise, display it as text -->
          <div
            *ngIf="
              isHtmlContent(confirmDialogData.content);
              else plainTextContent
            "
            [innerHTML]="getSanitizedContent(confirmDialogData.content)"
          ></div>
          <ng-template #plainTextContent>
            <p>{{ confirmDialogData.content }}</p>
          </ng-template>
        </ng-container>

        <!-- Input field for audit comments -->
        <ng-container *ngIf="auditCommentsVisible">
          <input
            type="text"
            nz-input
            i18n-placeholder
            placeholder="Audit Comments"
            [(ngModel)]="auditComments"
            (ngModelChange)="updateAuditComments()"
          />
        </ng-container>
      </ng-container>

      <ng-template [nzModalFooter]>
        <div class="flex-row flex-end confirm-controls">
          <xpw-button type="default" (click)="handleCancel()">
            {{ confirmDialogData.cancelText }}
          </xpw-button>
          <xpw-button type="primary" (click)="handleOk()">
            {{ confirmDialogData.okText }}
          </xpw-button>
        </div>
      </ng-template>
    </nz-modal>

    <br />
  `,
  styles: `
    .confirm-controls {
      gap: 8px;
    }
  `,
})
export class XpwConfirmDialogComponent {
  isVisible = false;
  auditComments: string = '';
  auditCommentsVisible: boolean = true;
  sanitizedContent: SafeHtml;

  confirmDialogData: ConfirmDialogData = {
    handleOk: () => {},
    title: 'Title',
    content: 'Are you sure?',
    auditCommentsVisible: true,
    okText: 'Confirm',
    cancelText: 'Cancel',
  };
  constructor(
    private confirmService: ConfirmDialogService,
    private store: Store,
    private sanitizer: DomSanitizer,
  ) {
    this.store
      .select(clearAuditAction)
      .subscribe(() => (this.auditComments = ''));

    this.confirmService.isVisible$.subscribe((res) => {
      this.isVisible = res;
    });
    this.confirmService.confirmDialogData$.subscribe((dialogData) => {
      this.confirmDialogData = dialogData;
    });
  }
  getSanitizedContent(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  isHtmlContent(content: string): boolean {
    const htmlPattern = /<\/?[a-z][\s\S]*>/i;
    return htmlPattern.test(content);
  }
  updateAuditComments(): void {
    this.store.dispatch(updateAuditAction({ message: this.auditComments }));
  }

  handleOk(): void {
    this.confirmDialogData.handleOk();
    this.confirmService.CloseDialog();
  }

  handleCancel(): void {
    this.confirmService.CloseDialog();
  }

  //   showConfirm(): void {
  //     this.modalService.confirm({
  //       nzTitle: 'Confirm',
  //       nzContent: 'Bla bla ...',
  //       nzOkText: 'OK',
  //       nzCancelText: 'Cancel',
  //     });
  //   }
}
