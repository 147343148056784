import { Injectable } from '@angular/core';
import { LocationService } from '@core/api/location.service';
import { NotificationService } from '@core/services/notifications/notifications.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as LocationActions from './location.actions';
import {
  ICountry,
  ICountryState,
  IDepratmentCountries,
} from '@shared/utility/global-enums';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import {
  selectCountries,
  selectSelectedCountry,
  selectSelectedState,
} from './location.selector';
import { selectCurrentUserDepartmentUID } from '../current-user/current-user.selector';
import { selectDepartmentUID } from '@features/organizations/organizations-store/organizations.selector';

@Injectable()
export class LocationEffects {
  constructor(
    private actions$: Actions,
    private apiService: LocationService,
    private notification: NotificationService,
    private store: Store,
  ) {}

  getCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.getAllCountries), // Fix action name
      mergeMap(() =>
        // Specify the type of 'action' parameter
        this.apiService.getAllCountries().pipe(
          map((countries: ICountry[]) =>
            LocationActions.getAllCountriesSuccess({ countries }),
          ), // Fix action name
          catchError((error: any) => {
            // Specify the type of 'error' parameter
            return of(LocationActions.locationError({ error }));
          }),
        ),
      ),
    ),
  );

  getDepartmentCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.getDepartmentCountries),
      withLatestFrom(this.store.select(selectDepartmentUID)),
      filter(([_, currentDepartmentUID]) => {
        return currentDepartmentUID !== '0';
      }),
      switchMap((action) =>
        this.apiService.getDepartmentCountries(action[1]).pipe(
          map((departmentCountries: IDepratmentCountries) =>
            LocationActions.getCountriesSuccess({ departmentCountries }),
          ), // Fix action name
          catchError((error: any) => {
            // Specify the type of 'error' parameter
            return of(LocationActions.locationError({ error }));
          }),
        ),
      ),
    ),
  );

  // getStateCities$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(LocationActions.getStateCities), // Fix action name
  //     switchMap((action: { stateID: number }) =>
  //       this.apiService.getStateCities(action.stateID).pipe(
  //         map((cities: string[]) =>
  //           LocationActions.getCitiesSuccess({ cities }),
  //         ), // Fix action name
  //         catchError((error: any) => {
  //           // Specify the type of 'error' parameter
  //           return of(LocationActions.locationError({ error }));
  //         }),
  //       ),
  //     ),
  //   ),
  // );

  getCountryCities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.getCountryCities), // Fix action name
      switchMap((action: { countryId: number }) =>
        this.apiService.getCountryCities(action.countryId).pipe(
          map((cities: string[]) =>
            LocationActions.getCitiesSuccess({ cities }),
          ), // Fix action name
          catchError((error: any) => {
            // Specify the type of 'error' parameter
            return of(LocationActions.locationError({ error }));
          }),
        ),
      ),
    ),
  );

  locationError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LocationActions.locationError), // Fix action name
        tap((action: { error: any }) => {
          // Specify the type of 'action' parameter
          //this.notification.error(action.error);
        }),
      ),
    { dispatch: false },
  );

  setState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.setState), // Fix action name
      withLatestFrom(this.store.select(selectSelectedState)), // Specify the type of 'state' parameter
      switchMap((action) => {
        const [_, state] = action;
        return of(LocationActions.getStateCities({ stateID: state.id }));
      }),
    ),
  );

  // setCountry$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(LocationActions.setCountry),
  //     withLatestFrom(this.store.select(selectSelectedCountry)), // Specify the type of 'state' parameter
  //     switchMap((action) => {
  //       const [_, country] = action;
  //       if (country.hasStates) {
  //         return of(
  //           LocationActions.getCountryStates({ countryID: country.id }),
  //         );
  //       } else {
  //         return of(
  //           LocationActions.getCountryCities({ countryId: country.id }),
  //         );
  //       }
  //     }),
  //   ),
  // );

  getCitiesBySearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.getCitiesBySearch), // Fix action name
      withLatestFrom(
        this.store.select(selectSelectedCountry),
        this.store.select(selectSelectedState),
      ),
      switchMap(([action, selectedCountry, selectedState]) =>
        this.apiService
          .getCitiesBySearch(
            action.search,
            selectedCountry.id,
            selectedState?.id ?? null,
          )
          .pipe(
            map((cities: string[]) =>
              LocationActions.getCitiesSuccess({ cities }),
            ), // Fix action name
            catchError((error: any) => {
              // Specify the type of 'error' parameter
              return of(LocationActions.locationError({ error }));
            }),
          ),
      ),
    ),
  );
}
