import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { DrawService } from '@core/services/popup/drawer.service';
import { PopupService } from '@core/services/popup/popup.modal.service';

@Component({
  selector: 'xpw-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.less'],
})
export class LayoutComponent implements OnInit {
  isCollapsed = false;
  isOpen = true;

  constructor(
    private router: Router,
    private viewContainerRef: ViewContainerRef,
    private drawerService: DrawService,
    private modalService: PopupService,
  ) {
    this.drawerService.setViewContainerRef(this.viewContainerRef);
    this.modalService.setViewContainerRef(this.viewContainerRef);
  }

  ngOnInit() {
    this.isOpen = false;
  }

  navigateToLink(link: string) {
    this.router.navigateByUrl(link);
  }
}
