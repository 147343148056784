import { Component } from '@angular/core';
import {
  closeIconUploadPopup,
  putIconToSelectedOrganization,
} from '@features/organizations/organizations-store/organizations.actions';
import { selectUploadOrganozationIconPopupVisible } from '@features/organizations/organizations-store/organizations.selector';
import { Store } from '@ngrx/store';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile, NzUploadType } from 'ng-zorro-antd/upload';
import { selectCurrentUserOrganizationUIDLocation } from 'src/app/store/current-user/current-user.selector';

@Component({
  selector: 'xpw-image-upload-popup',
  template: `
    <nz-modal
      [(nzVisible)]="isVisible"
      nzTitle="Upload Image"
      (nzOnCancel)="handleCancel()"
    >
      <div *nzModalContent>
        <div nz-row>
          <div nz-col nzSpan="24">
            <nz-upload
              [nzAccept]="'.jpg,.png,.svg'"
              [nzLimit]="1"
              [nzType]="type"
              [nzMultiple]="false"
              [nzShowUploadList]="false"
              (nzChange)="onUploadChange($event)"
              [nzBeforeUpload]="beforeUpload"
            >
              <div *ngIf="imageSrc" class="image-preview">
                <img [src]="imageSrc" alt="Preview" />
              </div>

              <div *ngIf="!imageSrc" class="ant-upload-text">
                <p class="ant-upload-drag-icon">
                  <xpw-icon icon="inbox" />
                </p>
                <p class="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p class="ant-upload-hint">
                  Images formats supported: JPG, PNG, SVG.
                </p>
              </div>
            </nz-upload>
          </div>
        </div>
      </div>

      <div *nzModalFooter>
        <button nz-button nzType="default" (click)="handleCancel()">
          Cancel
        </button>
        <button
          [disabled]="!imageSrc"
          nz-button
          nzType="primary"
          (click)="handleOk()"
        >
          Continue
        </button>
      </div>
    </nz-modal>
  `,
  styleUrls: ['./image-upload-popup.component.less'],
})
export class XpwUploadImagePopupComponent {
  isVisible = false;
  type: NzUploadType = 'drag';
  imageSrc: string | ArrayBuffer | null = null;

  constructor(
    private store: Store,
    private msg: NzMessageService,
  ) {
    this.store
      .select(selectUploadOrganozationIconPopupVisible)
      .subscribe((isVisible) => (this.isVisible = isVisible));
  }

  showDialog(): void {
    this.isVisible = true;
  }

  handleCancel(): void {
    this.store.dispatch(closeIconUploadPopup());
    this.imageSrc = null;
  }

  handleOk(): void {
    this.uploadImage();
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    const isLt500KB = file.size! / 1024 < 500;
    const img = new Image();
    img.src = window.URL.createObjectURL(file as any);

    img.onload = () => {
      const isValidResolution = img.width <= 100 && img.height <= 100;
      if (!isLt500KB) {
        this.msg.error('Image must be smaller than 500KB!');
      }
      if (!isValidResolution) {
        this.msg.error('Image must be 100x100 pixels!');
      }
      if (isLt500KB && isValidResolution) {
        const reader = new FileReader();
        reader.onload = () => (this.imageSrc = reader.result);
        reader.readAsDataURL(file as any);
      }
    };

    return false; // Prevent the default upload action as we manually handle it
  };

  onUploadChange(event: any): void {
    const file = event.file.originFileObj;
    if (file) {
      this.beforeUpload(file);
    }
  }

  uploadImage(): void {
    if (this.imageSrc) {
      this.store.dispatch(
        putIconToSelectedOrganization({
          iconImage: this.convertImageSrcToBase64(this.imageSrc),
        }),
      );
      this.imageSrc = null;
      this.store.dispatch(closeIconUploadPopup());
    } else {
      this.msg.error('Please select a valid image first.');
    }
  }

  convertImageSrcToBase64(
    imageSrc: string | ArrayBuffer | null,
  ): string | null {
    if (imageSrc instanceof ArrayBuffer) {
      const binary = String.fromCharCode(...new Uint8Array(imageSrc));
      return btoa(binary);
    } else if (typeof imageSrc === 'string') {
      return imageSrc;
    } else {
      return null; // Or handle null case as needed
    }
  }
}
