import { UtilityService } from '@features/organizations/organizations-store/organizations.interface';
import { IPagingResponse } from '@shared/utility/global-enums';

// Interface for working with any Meter.
export interface IMeter {
  meterUID: string | null;
  internalMeterID: string | null;
  createDate: Date | null;
  lastConsumption: Date | null;
  meterGeneralInfo: IMeterInfo;
  currentReplacement: IMeterReplacement | null;
  transformerSetting: ITransformerSetting | null;
  groupUIDs: string[] | null;
}
export type MeterGroups = { [key: string]: string };

export type MeterName = {
  uid: string;
  name: string;
  utilityServiceID: UtilityService;
};

export interface IMeterInfo {
  meterName: string | null;
  timeZoneID: string | null;
  isVirtual: boolean | null;
  powerSourceUID: string | null;
  stateID: string | null;
  city: string | null;
  zipCode: string | null;
  streetAddress: string | null;
  latitude: number | null;
  longitude: number | null;
  locationID: string | null;
  utilityServiceID: number | null;
  measuringPointTypeID: MeasuringPointType | null;
  meterStatusID: MeterStatus | null;
}

export interface IMetersResult {
  items: IMeter[];
  pagingInfo: IPagingResponse;
  isLoading: boolean;
}

export interface IMeterReplacement {
  meterReplacementUID: string | null;
  externalID: string | null;
  serialNumber: string | null;
  installationDate: Date | null;
  endDate: Date | null;
  multiplier: number | null;
  channelConfigurationUID: string | null;
  isOppositeConfiguration: boolean | null;
  isCurrent: boolean;
}

export interface ITransformerSetting {
  transformerTypeID: number | null;
  nominalValueKVA: number | null;
  optimalLoadPercentage: number | null;
  typicalPfForPvProducer: number | null;
  typicalPfForConsumer: number | null;
  overloadHoursThreshold: number | null;
}

export interface IReplacmentHystory {
  items: IMeterReplacement[];
  selectedReplacement: IMeterReplacement | null;
  isNewReplacement: boolean;
}

export enum MeterStatus {
  Active = 1,
  Suspended = 2,
  Deleted = 4,
}
export enum TransformerType {
  Single = 1,
  ThreePhase = 2,
}

export enum MeasuringPointType {
  Consumer = 1,
  ConsumerGross = 2,
  Transformer = 3,
  GridConnectionPoint = 4,
  Generator = 5,
  PVproducer = 6,
  SolarProducer = 7,
  WindProducer = 8,
  EVcharger = 9,
  BESS = 10,
}
