<div class="tree-node-row-header">
  <div class="flex-row align-center">
    <div class="items-part-title">
      <span>Group Name</span>
      <xpw-orderby-icon
        (changedOrder)="changedOrder('name', $event)"
      ></xpw-orderby-icon>
    </div>
  </div>

  <div class="flex-row align-center items-part">
    <div class="items-part-title">
      <span>Meters</span>
    </div>
    <div class="items-part-title">
      <span>Users</span>
    </div>
    <div class="items-part-title">
      <span>Creation Date</span>
    </div>
    <div class="items-part-title">
      <span>Actions</span>
    </div>
  </div>
</div>
<div class="custom-tree-container">
  <nz-tree-view
    [nzTreeControl]="treeControl"
    [nzDataSource]="dataSource"
    class="custom-tree"
    [nzBlockNode]="true"
  >
    <nz-tree-node
      *nzTreeNodeDef="let node"
      nzTreeNodePadding
      [id]="'node-' + node.uid"
    >
      <div
        class="tree-node-row"
        (click)="toggleNode(node)"
        [ngClass]="{ 'selected-node': node?.selected ?? false }"
      >
        <div class="flex-row align-center">
          <div class="items-part-table-rows">
            <xpw-icon *ngIf="node.isLeaf" icon="folder-full"></xpw-icon>
            <xpw-icon
              *ngIf="!node.isLeaf"
              [icon]="treeControl.isExpanded(node) ? 'folder-open' : 'folder'"
            ></xpw-icon>
            <span>{{ node.name }}</span>
          </div>
        </div>

        <div class="flex-row align-center items-part">
          <div class="items-part-table-rows">
            <span class="badge" *ngIf="node.meterCount > 0">{{
              node.meterCount
            }}</span>
          </div>
          <div class="items-part-table-rows">
            <span class="badge" *ngIf="node.usersCount > 0">{{
              node.usersCount || ''
            }}</span>
            <span *ngIf="node.usersCount === 0"></span>
          </div>
          <div class="items-part-table-rows">
            <span>
              <xpw-icon icon="calander"></xpw-icon>
              {{ node.createDate | xpwDateFormatter }}</span
            >
          </div>
          <div class="items-part-table-rows">
            <div class="tree-action-buttons">
              <xpw-button
                nz-tooltip
                nzTooltipTitle-i18n="@@TreeCreateGroup"
                nzTooltipTitle="Create Group Inside"
                nzTooltipPlacement="top"
                *ngIf="!node.isLeaf"
                type="text"
                (click)="createChildNode(node); $event.stopPropagation()"
              >
                <xpw-icon icon="add-plus"></xpw-icon>
              </xpw-button>
              <span style="width: 48px" *ngIf="node.isLeaf"></span>
              <xpw-button
                nz-tooltip
                nzTooltipTitle-i18n="@@TreeEditGroup"
                nzTooltipTitle="Edit Group"
                nzTooltipPlacement="top"
                type="text"
                (click)="editNode(node); $event.stopPropagation()"
              >
                <xpw-icon icon="edit"></xpw-icon>
              </xpw-button>

              <xpw-button
                nz-tooltip
                nzTooltipTitle-i18n="@@TreeDeleteGroup"
                nzTooltipTitle="Delete Group"
                (click)="deleteNode(node); $event.stopPropagation()"
                type="text"
              >
                <xpw-icon icon="delete"></xpw-icon>
              </xpw-button>
            </div>
          </div>
        </div>
      </div>
    </nz-tree-node>
  </nz-tree-view>
</div>
