import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IPagingRequest, IFilterOptions } from '@shared/utility/global-enums';
import {
  IMeter,
  IMeterReplacement,
  MeasuringPointType,
  MeterName,
  MeterStatus,
} from '@features/meters/meters-store/meters.interface';
import { GlobalFunctions } from '@shared/utility/global-functions';
import { SelectMetersService } from '@features/select-meters/service/select-meters.service';
import { DataService } from '@core/services/data/data.service';

@Injectable({
  providedIn: 'root',
})
export class MetersService implements SelectMetersService {
  apiBaseUrl = '';
  endpoint = 'organizations';

  constructor(
    private http: HttpClient,
    private dataService: DataService,
  ) {
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  getMetersList(
    pagingRequest: IPagingRequest,
    filter: any,
    search: string,
    isExport: boolean = false,
    options?: any,
  ): Observable<any> {
    return this.http.get<any>(`${this.apiBaseUrl}${this.endpoint}/meters`, {
      params: {
        PageNumber: pagingRequest.pageNumber,
        PageSize: pagingRequest.pageSize,
        OrderBy: pagingRequest.orderBy,
        IsDescending: pagingRequest.isDescending,
        MeterUID: filter?.MeterUID,
        MeterGroupUID: filter?.MeterGroupUID,
        UtilityServiceId: filter?.UtilityServiceId,
        MeasuringPointTypeId: filter?.MeasuringPointTypeId,
        PowerSourceUID: filter?.PowerSourceUID,
        ChannelConfigurationUID: filter?.ChannelConfigurationUID,
        IsVirtual: filter?.IsVirtual,
        IsOppositeConfiguration: filter?.IsOppositeConfiguration,
        StateId: filter?.StateId,
        City: filter?.City,
        search: search,
        isExport: isExport,
      },
      ...options,
    });
  }
  getMeters(paging: any, search: string): Observable<any[]> {
    return this.dataService.getData(
      `${this.apiBaseUrl}${this.endpoint}/meters/name`,
      {
        pageNumber: paging.pageNumber,
        pageSize: paging.pageSize,
        orderBy: paging.orderBy,
        isDescending: paging.isDescending,
        search: search,
      },
    );
    // return this.http.get<any>(
    //   `${this.apiBaseUrl}${this.endpoint}/meters/name`,
    //   {
    //     params: {
    //       pageNumber: paging.pageNumber,
    //       pageSize: paging.pageSize,
    //       orderBy: paging.orderBy,
    //       isDescending: paging.isDescending,
    //       search: search,
    //     },
    //   },
    // );
  }
  getMeterNameList(): Observable<MeterName[]> {
    return this.http.get<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/name`,
      {},
    );
  }

  getMeterFilters(): Observable<any> {
    return this.http.get<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/filters`,
      {},
    );
  }

  changeStatus(meterUID: string, status: MeterStatus): Observable<any> {
    return this.http.put<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/${meterUID}/status`,
      { meterStatusID: status },
    );
  }

  deleteMeter(meterUID: string): Observable<any> {
    return this.http.delete<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/${meterUID}`,
    );
  }

  createMeter(meterDetails: IMeter): Observable<any> {
    return this.http.post<any>(`${this.apiBaseUrl}${this.endpoint}/meters`, {
      meterGeneralInfo: meterDetails.meterGeneralInfo,
      currentReplacement: meterDetails.currentReplacement,
      transformerSetting: meterDetails.transformerSetting,
      groupUIDs: meterDetails.groupUIDs,
    });
  }

  updateMeter(meterDetails: IMeter): Observable<any> {
    return this.http.put<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/${meterDetails.meterUID}`,
      {
        internalMeterID: meterDetails.internalMeterID,
        meterGeneralInfo: meterDetails.meterGeneralInfo,
        currentReplacement: meterDetails.currentReplacement,
        transformerSetting: meterDetails.transformerSetting,
        groupUIDs: meterDetails.groupUIDs,
      },
    );
  }

  getReplacementHistory(meterUID: string): Observable<IMeterReplacement[]> {
    return this.http.get<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/${meterUID}/replacement/history`,
      {},
    );
  }

  createReplacement(
    meterUID: string,
    replacement: IMeterReplacement,
  ): Observable<any> {
    return this.http.post<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/${meterUID}/replacement`,
      replacement,
    );
  }

  updateReplacement(
    meterUID: string,
    replacement: IMeterReplacement,
  ): Observable<any> {
    return this.http.put<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/${meterUID}/replacement/${replacement.meterReplacementUID}`,
      replacement,
    );
  }

  deleteReplacement(
    meterUID: string,
    meterReplacementUID: string,
  ): Observable<any> {
    return this.http.delete<any>(
      `${this.apiBaseUrl}${this.endpoint}/meters/${meterUID}/replacement/${meterReplacementUID}`,
    );
  }
}
