import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterItem } from './xpw-abstract-table-list.component';
import { ErrorKeyLabels } from '@shared/utility/errors-keys-msg';
import { EnumTitleType } from '@shared/utility/global-enums-titles';

@Component({
  selector: 'xpw-table-header',
  template: `
    <thead>
      <tr>
        <th>
          <span i18n>Filtered By:</span>

          <span
            style="border: 1px solid #e6e6e6;
            padding: 10px;
            margin: 5px;
            border-radius: 2px;"
            *ngFor="let filtertype of filterDataForHeader | keyvalue"
          >
            <span>{{ stringKey[filtertype.key] }}: </span>
            <span *ngFor="let fData of filtertype.value">
              <xpw-tag-closable
                [label]="getTitle(fData)"
                (onCloseClick)="propertyRemoved(fData)"
              />
            </span>
          </span>
        </th>
        <xpw-button (click)="clearFilters()" type="text" i18n="@@Clear">
          <xpw-icon icon="clear" />
          Clear
        </xpw-button>
      </tr>
    </thead>
  `,
})
export class XpwTableHeaderComponent {
  @Input() filterDataForHeader: { [key: string]: any[] };
  @Input() filterTitles: { [key: string]: EnumTitleType } = {};
  @Output() filterPropertyRemoved = new EventEmitter<FilterItem>();
  @Output() clearAllFilters = new EventEmitter<void>();

  stringKey = ErrorKeyLabels;
  clearFilters() {
    this.clearAllFilters.emit();
  }
  getTitle(fData: any): string {
    return this.filterTitles[fData.type]
      ? this.filterTitles[fData.type][fData.value]
      : fData.label;
  }
  propertyRemoved(removedProperty: FilterItem) {
    this.filterPropertyRemoved.emit(removedProperty);
  }
}
