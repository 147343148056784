import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Role } from '@features/user/user-store/user.interface';
import { ErrorKey } from '@shared/utility/errors-keys-msg';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Subscription } from 'rxjs';

@Component({
  template: ``,
})
export class BaseDrawerTemplateComponent {
  //implements OnDestroy {
  @ViewChild('componentTemplate') componentTemplate: TemplateRef<{
    drawerRef: NzDrawerRef<string>;
  }>;
  role = Role;
  xpwForm: FormGroup;
  Subscriptions$: Subscription[] = [];

  handleServerErrors(
    ValidationErrors: HttpErrorResponse,
    callback?: () => void,
  ) {
    console.log('handleErrors', ValidationErrors);
    if (ValidationErrors.error) {
      const errors = ValidationErrors.error.validationErrorKeys;

      Object.keys(errors).forEach((key) => {
        let controlErrors: { [key: string]: boolean } = {};
        errors[key].map((errorK) => {
          const errorKey = errorK;
          console.log('errorKey', errorKey.split('$'));
          controlErrors[ErrorKey[errorKey]] = true;
        });
        if (key.includes('organizationRequests')) key = key + '.frequency';
        if (key.includes('parameters')) key = key + '.value';
        this.xpwForm?.get(key).setErrors(controlErrors);
      });
    }

    if (callback) {
      callback();
    }
  }

  checkErrorsFromForm(listOfKeys: string[]): boolean {
    return listOfKeys.some((key) => this.xpwForm.get(key)?.errors != null);
  }
}
