import { Component, OnInit } from '@angular/core';
import { CurrentUserFlowService } from '@core/services/current-user/current-user.service';
import { Role } from '@features/user/user-store/user.interface';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ICurrentUser } from 'src/app/store/current-user/current-user.interface';
import { selectCurrentUserProperties } from 'src/app/store/current-user/current-user.selector';

@Component({
  selector: 'xpw-user-profile',
  template: `<div>
    <nz-avatar
      [nzText]="usernameLetters"
      style="background-color:#87E8DE;"
    ></nz-avatar>

    <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
      {{ userName }}
      <span nz-icon nzType="down"></span>
    </a>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li
          *ngIf="showCurrentOrganization()"
          nz-menu-item
          routerLink="/user-profile"
        >
          Switch organization
        </li>
        <li *ngIf="showCurrentOrganization()" nz-menu-divider></li>

        <li nz-menu-item (click)="logout()">Logout</li>
      </ul>
    </nz-dropdown-menu>
  </div>`,
})
export class UserProfileComponent implements OnInit {
  currentUser$: Observable<ICurrentUser> = this.store.select(
    selectCurrentUserProperties,
  );
  userName: string = 'USER NAME';
  usernameLetters: string = 'UN';
  currentUserRoles: Role[] = [];
  constructor(
    private store: Store,
    private currentUserFlowService: CurrentUserFlowService,
  ) {}
  ngOnInit() {
    this.currentUser$.subscribe((user) => {
      if (user) {
        this.currentUserRoles = user.roles;

        this.usernameLetters =
          user.firstName.charAt(0) + user.lastName.charAt(0);
        this.userName = user.firstName + ' ' + user.lastName;
      }
    });
  }
  showCurrentOrganization() {
    return (
      this.currentUserRoles.includes(Role.DepartmentAdmin) ||
      this.currentUserRoles.includes(Role.SysAdmin)
    );
  }
  logout() {
    this.currentUserFlowService.logout();
  }
}
