import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import { UtilityService } from '@features/organizations/organizations-store/organizations.interface';

@Component({
  selector: 'xpw-utility-service-tag',
  template: `
    <nz-tag [nzColor]="color">
      <xpw-icon [icon]="icon" />
      <span>{{ stringTitle }}</span>
    </nz-tag>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XpwUtilityServiceTagComponent implements OnChanges {
  @Input() serviceId: UtilityService = null;
  stringTitle: string = '';
  icon: string | null = null;
  color: string = 'default';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['serviceId']) {
      this.getData();
    }
  }
  getData() {
    switch (this.serviceId) {
      case UtilityService.Electricity:
        this.stringTitle = $localize`Electricity`;
        this.icon = 'utility-electricity';
        this.color = 'cyan';
        break;
      case UtilityService.Gas:
        this.stringTitle = $localize`Gas`;
        this.icon = 'utility-gas';
        this.color = 'orange';
        break;
      case UtilityService.Water:
        this.stringTitle = $localize`Water`;
        this.icon = 'utility-water';
        this.color = 'blue';
        break;
      default:
        this.stringTitle = $localize`Unknown`;
    }
  }
}
