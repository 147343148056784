import { culture } from './culture/culture';

export const environment = {
  production: true,
  // Variables for AZUR AD
  clientId: '987f2aaa-6377-46c8-b706-2ba3142b7185', // Application (client) ID from the app registration
  redirectUri: `https://web.dev.expertpower.com/${culture.env}`, // This is your redirect URI
  apiBaseUrl: 'https://webapi.dev.expertpower.com/api/', // base URL for API calls
  apiBaseWS: 'https://webapi.dev.expertpower.com/', // base URL for API calls
  b2cPolicies: {
    names: {
      signUpSignIn: 'B2C_1A_SIGNIN_DEV',
    },
    authorities: {
      signUpSignIn: {
        authority:
          'https://expertpowerb2c.b2clogin.com/expertpowerb2c.onmicrosoft.com/B2C_1A_SIGNIN_DEV',
      },
    },
    authorityDomain: 'expertpowerb2c.b2clogin.com',
  },
  ApiScopes: [
    'offline_access',
    'openid',
    'https://expertpowerb2c.onmicrosoft.com/xpwApi/api.scope',
  ],
  appInsights: {
    instrumentationKey: 'f3bbbcdc-2a6e-4b49-a4fd-69b0126c19b8',
  },
};
