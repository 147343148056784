import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputArrayComponent } from '../abstract-input-array.component';

@Component({
  selector: 'xpw-form-search-and-select',
  template: `
    <nz-form-item>
      <nz-form-label *ngIf="label">
        {{ label }} {{ isOptional ? ' (Optional)' : '' }}
      </nz-form-label>
      <nz-form-control
        [nzHasFeedback]="hasFeedback"
        [nzValidateStatus]="errorStatus()"
        [nzErrorTip]="getErrorLabel()"
      >
        <xpw-form-input-text
          placeholder="Search..."
          (input)="onSearch($event)"
        />

        <!-- Scrollable List Container -->
        <div nz-scroll style="height: 300px; overflow-y: auto;">
          <nz-list
            *ngIf="filteredOptions.length > 0"
            [nzDataSource]="paginatedOptions()"
            [nzRenderItem]="item"
          >
            <ng-template #item let-item>
              <nz-list-item
                (click)="selectChange(item)"
                [ngClass]="{ 'selected-item': isSelected(item.value) }"
              >
                {{ item.label }}
              </nz-list-item>
            </ng-template>
          </nz-list>
          <div *ngIf="filteredOptions.length === 0">No options available.</div>
        </div>

        <nz-pagination
          *ngIf="filteredOptions.length > 100"
          [(nzPageIndex)]="pageIndex"
          [nzPageSize]="100"
          [nzTotal]="filteredOptions.length"
          (nzPageIndexChange)="onPageChange()"
        ></nz-pagination>
      </nz-form-control>
    </nz-form-item>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XpwSearchAndSelectFormComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      .selected-item {
        background-color: #e6f7ff;
        border-left: 5px solid #1890ff;
      }
      nz-list-item {
        padding: 4px 16px;
      }
    `,
  ],
})
export class XpwSearchAndSelectFormComponent extends AbstractInputArrayComponent {
  @Input('options') options: { label: string; value: string }[] = []; // Using nz-options format
  @Input('label') label: string;
  @Input('disabled') disabledInput: boolean = false;
  @Input('icon') icon: string = 'down';
  @Input() hasFeedback: boolean = true;
  @Output() onSelectedChange = new EventEmitter();
  @Output() nameForSearch = new EventEmitter<string>();

  filteredOptions: { label: string; value: string }[] = [];
  pageIndex: number = 1;
  selectedItems: string[] = []; // Store UIDs as selected items

  ngOnInit() {
    this.filteredOptions = this.options;
    console.log('Filtered options on init:', this.filteredOptions); // Debug logging
  }

  selectChange(selectedItem: { value: string }) {
    const selectedUID = selectedItem.value;
    const index = this.selectedItems.indexOf(selectedUID);

    if (index > -1) {
      this.selectedItems.splice(index, 1); // Unselect item
    } else {
      this.selectedItems.push(selectedUID); // Select item
    }

    this.onSelectedChange.emit(this.selectedItems); // Emit event with selected UIDs
    this.onChange(this.selectedItems); // Call onChange with the selected UIDs
  }

  isSelected(UID: string): boolean {
    return this.selectedItems.includes(UID);
  }

  onSearch(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const searchText = inputElement.value;

    this.filteredOptions = this.options.filter((option) =>
      option.label.toLowerCase().includes(searchText.toLowerCase()),
    );
    this.pageIndex = 1; // Reset to first page when search changes
    this.nameForSearch.emit(searchText);
    console.log('Filtered options after search:', this.filteredOptions); // Debug logging
  }

  onPageChange() {
    // Handle page change logic if needed
  }

  paginatedOptions() {
    const start = (this.pageIndex - 1) * 100;
    return this.filteredOptions.slice(start, start + 100);
  }

  get suffixIcon() {
    return `<xpw-icon [icon]="'xpw-outline:' + icon"></xpw-icon>`;
  }
}
