import { routerReducer } from '@ngrx/router-store';
import { currentUserReducer } from './current-user/current-user.reducer';
import { OrganizationReducer } from '@features/organizations/organizations-store/organizations.reducer';
import { DepartmentReducer } from '@features/departments/departments-store/departments.reducer';
import { auditReducer } from './audit/audit.reducer';
import { locationReducer } from './location/location.reducer';
import { MeterReducer } from '@features/meters/meters-store/meters.reducer';
import { fileImportReducer } from './file-import/file-import.reducer';
import { MeterGroupReducer } from '@features/meter-group/store/meter-group.reducer';

export const rootReducers = {
  audit: auditReducer,
  currentUser: currentUserReducer,
  router: routerReducer,
  organizations: OrganizationReducer,
  departments: DepartmentReducer,
  location: locationReducer,
  meters: MeterReducer,
  fileImport: fileImportReducer,
  meterGroups: MeterGroupReducer,
};
