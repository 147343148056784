import { createAction, props } from '@ngrx/store';
import {
  IMeter,
  IMeterReplacement,
  IMetersResult,
  MeterName,
  MeterStatus,
} from './meters.interface';
import { IPagingRequest, IFilterOptions } from '@shared/utility/global-enums';

export enum MetersActionType {
  getMeterList = '[Meter] Get meters List',
  getMeterListSuccess = '[Meter] Get meters List Success',
  getMeterNameList = '[Meter] Get meter names List',
  getMeterNameListSuccess = '[Meter] Get meter names List Success',
  getMeterListFailure = '[Meter] Get meters List Failure',
  validationErrors = '[Meter] Got validations error',
  selectMeter = '[Meter] Select Meter',
  selectNewMeter = '[Meter] Create New Meter in the store',
  createMeter = '[Meter] Create Meter',
  createMeterSuccess = '[Meter] Create Meter success',
  updateMeter = '[Meter] Update Meter',
  updateMeterSuccess = '[Meter] Update Meter Success',
  deleteMeter = '[Meter] Delete Meter',
  deleteMeterSuccess = '[Meter] Delete MeterSuccess',
  closeMeterDrawer = '[Meter] Close Meter Drawer',
  changeStatus = '[Meter] Change status Meter',
  ShowErrorMessage = '[Meter] Show Errors Messages',
  ShowMetersSuccessMessage = '[Meter] Show Meters Messages Success',
  getReplacementHystory = '[Meter] Get Replacement Hystory',
  getReplacementHystorySuccess = '[Meter] Get Replacement Hystory Success',
  createReplacement = '[Meter] Create Replacement',
  createReplacementSuccess = '[Meter] Create Replacement  Success',
  selectReplacement = '[Meter] select Replacement',
  selectNewReplacement = '[Meter] select New Replacement',
  updateReplacement = '[Meter] Update Replacement ',
  updateReplacementSuccess = '[Meter] Update Replacement  Success',
  deleteReplacement = '[Meter] Delete Replacement ',
  deleteReplacementSuccess = '[Meter] Delete Replacement  Success',
  closeReplacementDrawer = '[Meter] Close replacement Drawer',
  PaginationChange = '[Meter] Pagination Change',
  FilterChange = '[Meter] Filter Change',
  SearchChange = '[Meter] Search Change',
  getFilterOptions = '[Meter] Get Filters Options',
  getFilterOptionsSuccess = '[Meter] Get Filters Options success',
  resetMeterFilterAndSearch = '[Meter] Reset Filter And Search',
  exportMeterList = '[Meter] Export Meter List',
  exportMeterListSuccess = '[Meter] Export Meter List Success',

  ResetMeterState = '[Meter] Reset Meter State',
}

// Meter list
export const getMeterList = createAction(
  MetersActionType.getMeterList,
  props<{ pagingRequest: IPagingRequest }>(),
);

export const getMeterListSuccess = createAction(
  MetersActionType.getMeterListSuccess,
  props<{ meters: IMetersResult }>(),
);

export const getMeterNameList = createAction(MetersActionType.getMeterNameList);
export const closeMeterDrawer = createAction(MetersActionType.closeMeterDrawer);

export const getMeterNameListSuccess = createAction(
  MetersActionType.getMeterNameListSuccess,
  props<{ meterNames: MeterName[] }>(),
);

// CRUD for a meter
export const selectMeter = createAction(
  MetersActionType.selectMeter,
  props<{ meterUID: string }>(),
);
export const changeStatus = createAction(
  MetersActionType.changeStatus,
  props<{ updatedMeter: IMeter; status: MeterStatus }>(),
);
export const deleteMeter = createAction(
  MetersActionType.deleteMeter,
  props<{ meterUID: string }>(),
);

export const deleteMeterSuccess = createAction(
  MetersActionType.deleteMeterSuccess,
  props<{ meterUID: string }>(),
);

export const selectNewMeter = createAction(MetersActionType.selectNewMeter);

export const createMeter = createAction(
  MetersActionType.createMeter,
  props<{ meter: IMeter }>(),
);

export const createMeterSuccess = createAction(
  MetersActionType.createMeterSuccess,
  props<{ createdMeter: IMeter }>(),
);

export const updateMeter = createAction(
  MetersActionType.updateMeter,
  props<{ meter: IMeter }>(),
);
export const updateMeterSuccess = createAction(
  MetersActionType.updateMeterSuccess,
  props<{ updatedMeter: IMeter }>(),
);

export const showErrors = createAction(
  MetersActionType.ShowErrorMessage,
  props<{ message: string }>(),
);

export const showMetersSuccessMessage = createAction(
  MetersActionType.ShowMetersSuccessMessage,
  props<{ message: string }>(),
);

export const getReplacementHystory = createAction(
  MetersActionType.getReplacementHystory,
);
export const getReplacementHystorySuccess = createAction(
  MetersActionType.getReplacementHystorySuccess,
  props<{ replacementList: IMeterReplacement[] }>(),
);

export const createReplacement = createAction(
  MetersActionType.createReplacement,
  props<{ replacement: IMeterReplacement }>(),
);
export const createReplacementSuccess = createAction(
  MetersActionType.createReplacementSuccess,
);

export const selectReplacement = createAction(
  MetersActionType.selectReplacement,
  props<{ replacementUID: string }>(),
);
export const selectNewReplacement = createAction(
  MetersActionType.selectNewReplacement,
);

export const updateReplacement = createAction(
  MetersActionType.updateReplacement,
  props<{ replacement: IMeterReplacement }>(),
);
export const updateReplacementSuccess = createAction(
  MetersActionType.updateReplacementSuccess,
);

export const closeReplacementDrawer = createAction(
  MetersActionType.closeReplacementDrawer,
);

export const deleteReplacement = createAction(
  MetersActionType.deleteReplacement,
  props<{ meterReplacementUID: string }>(),
);
export const deleteReplacementSuccess = createAction(
  MetersActionType.deleteReplacementSuccess,
);

export const validationError = createAction(
  MetersActionType.validationErrors,
  props<{ ValidationErrors: any }>(),
);

export const paginationChange = createAction(
  MetersActionType.PaginationChange,
  props<{ pagingRequest: IPagingRequest; filter: any }>(),
);
export const filterChange = createAction(
  MetersActionType.FilterChange,
  props<{ filter: any }>(),
);
export const searchChange = createAction(
  MetersActionType.SearchChange,
  props<{ search: string }>(),
);

export const getFilterOptions = createAction(MetersActionType.getFilterOptions);

export const getFilterOptionsSuccess = createAction(
  MetersActionType.getFilterOptionsSuccess,
  props<{ filterOptions: IFilterOptions[] }>(),
);

export const exportMeterList = createAction(MetersActionType.exportMeterList);

export const exportMeterListSuccess = createAction(
  MetersActionType.exportMeterListSuccess,
  props<{ response: any }>(),
);

export const resetMeterFilterAndSearch = createAction(
  MetersActionType.resetMeterFilterAndSearch,
);

export const resetMeterState = createAction(MetersActionType.ResetMeterState);
